'use client'
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import useRegisterCoverage from '@/services/hooks/requests/Coverage/useRegisterCoverage';

interface AssignBahiaBlancaCoverageProps {
  uid: string;
}

const AssignBahiaBlancaCoverage: React.FC<AssignBahiaBlancaCoverageProps> = ({ uid }) => {
  console.log('AssignBahiaBlancaCoverage');
  const registerCoverage = useRegisterCoverage(uid);

  useEffect(() => {
    const injectOSS = Cookies.get('injectOSS');
    console.log(`injectOSS: ${injectOSS} and uid: ${uid}`)
    if (injectOSS?.toLowerCase() === 'bahiablanca' && uid) {
      console.log(injectOSS)
      const dependantUid = undefined; // TODO: resolved or fetch if needed
      const coverageInfo = {
        name: 'BAHIA BLANCA',
        affiliateId: '0',
        plan: 'default',
        userInput: 'BAHIA BLANCA',
        credentialVersion: undefined,
      };

      registerCoverage.mutate({
        dependantUid,
        coverage: coverageInfo,
      }, {
        onSuccess: () => {
          // Clear the injectOSS cookie
          Cookies.remove('injectOSS');
          // Log a success message
          console.log('Coverage for BAHIA BLANCA successfully registered and injectOSS cookie cleared.');
        },
        onError: (error) => {
          console.error('Error registering coverage for BAHIA BLANCA:', error);
          Cookies.remove('injectOSS');
        }

      });
    }
  }, [uid]);


  return null;
};

export default AssignBahiaBlancaCoverage; 