/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useCallback } from 'react'
import {
	Carousel as CarouselPrimitive,
	CarouselContent,
	CarouselItem,
	type CarouselApi,
} from '@/storybook/components/clientComponents/Carousel/Carousel'
import Autoplay from 'embla-carousel-autoplay'
import { ChevronLeft, ChevronRight } from 'lucide-react'

interface CarouselProps {
	children: React.ReactNode
	opts?: any
	plugins?: any[]
	className?: string
	contentClassName?: string
	itemClassName?: string
	showDots?: boolean
	showArrows?: boolean
	dotsClassName?: string
	onSlideChange?: (index: number) => void
	autoplaySlides?: boolean
}

const Carousel: React.FC<CarouselProps> = ({
	children,
	opts = {},
	plugins = [],
	className = '',
	contentClassName = '',
	itemClassName = '',
	showDots = true,
	showArrows = false,
	dotsClassName = '',
	onSlideChange,
	autoplaySlides = true,
}) => {
	const [api, setApi] = useState<CarouselApi | null>(null)
	const [current, setCurrent] = useState(0)
	const [count, setCount] = useState(0)

	useEffect(() => {
		if (!api) return

		setCount(api.scrollSnapList().length)
		setCurrent(api.selectedScrollSnap())

		api.on('select', () => {
			const selectedSnap = api.selectedScrollSnap()
			setCurrent(selectedSnap)
			onSlideChange?.(selectedSnap)
		})
	}, [api, onSlideChange])

	const handleDotClick = useCallback(
		(index: number) => {
			if (api) {
				api.scrollTo(index)
			}
		},
		[api],
	)

	return (
		<section className="relative px-6">
			{showArrows && (
				<button
					onClick={() => api?.scrollPrev()}
					className="absolute left-[-16px] top-1/3 transform -translate-y-1/2 p-2 z-10"
				>
					<ChevronLeft size={24} />
				</button>
			)}

			<CarouselPrimitive
				opts={{
					align: 'center',
					...opts,
				}}
				plugins={[
					...(autoplaySlides ? [Autoplay({ delay: 4000 })] : []),
					...plugins,
				]}
				className={`w-full ${className}`}
				setApi={setApi}
			>
				<CarouselContent className={contentClassName}>
					{React.Children.map(children, (child, index) => (
						<CarouselItem key={index} className={itemClassName}>
							{React.cloneElement(child as React.ReactElement, {
								isActive: index === current,
							})}
						</CarouselItem>
					))}
				</CarouselContent>
			</CarouselPrimitive>

			{showArrows && (
				<button
					onClick={() => api?.scrollNext()}
					className="absolute right-[-16px] top-1/3 transform -translate-y-1/2 p-2 z-10"
				>
					<ChevronRight size={24} />
				</button>
			)}

			{showDots && (
				<div
					className={`py-8 text-center text-sm flex gap-2 justify-center items-center ${dotsClassName}`}
				>
					{Array.from({ length: count }).map((_, index) => (
						<div
							key={index}
							onClick={() => handleDotClick(index)}
							className={`h-3 rounded-lg transition-all cursor-pointer ${
								index === current
									? 'bg-[#90A4AE] w-8'
									: 'w-3 bg-[#CFD8DC] rounded-full'
							}`}
						/>
					))}
				</div>
			)}
		</section>
	)
}

export default Carousel
