import React, { useContext } from 'react'
import Events from './Events'
import { Title } from '@umahealth/occipital'
import '../styles/historyResume.scss'
import { IHistorySections } from '@umahealth/entities'
import { commonCorporate } from '@/components/Utils/isCommonCorporate'
import { clientContext } from '@/OldRouter/Providers/ProviderClient'

interface EventsHistory {
	HistorySection: IHistorySections[]
}

function EventsHistory({ HistorySection }: EventsHistory) {
	const client = useContext(clientContext)

	return (
		<section>
			{commonCorporate(client) ? (
				<>
					<div className="h-[0.5px] my-4 bg-[#90A4AE] w-[70%] mx-auto" />
					<h2 className="font-bold mt-10 mb-4 ml-2">Mi historial</h2>
				</>
			) : (
				<Title
					className="bg-primary px-4 py-4 rounded-t-md !text-grey-6"
					hierarchy="h2"
					weight="font-semibold"
					size="text-s"
				>
					Mi historial
				</Title>
			)}
			<Events sections={HistorySection} />
		</section>
	)
}

export default EventsHistory
