import ButtonService from '@/components/HomePage/Components/ServiceButton/ServiceButton'
import { commonCorporate } from '@/components/Utils/isCommonCorporate'
import { firestore } from '@/config/DBConnection'
import { suscriptionEvents } from '@/events/subscriptionsEvents'
import { useClientContext } from '@/OldRouter/Providers/ProviderClient'

import useContextUid from '@/services/firebase/useContextUid'
import CardSkeleton from '@/shared/components/CardSkeleton'
import { IShortcut } from '@umahealth/entities/src/entities/corporates'
import { useHasSubscription } from '@umahealth/fe-firebase'
import { IconsNames, Loader } from '@umahealth/occipital'
import { useRouter } from 'next/navigation'

export function SuscripcionesShortcut({ shortcut }: { shortcut: IShortcut }) {
	const uid = useContextUid()
	const client = useClientContext()
	const hasSuscription = useHasSubscription(firestore, uid ?? 'NO', { enabled: !!uid })
	const router = useRouter()

	const saveLogAndRedirect = (path: string) => {
		suscriptionEvents.trackEventShortcutSuscriptions(
			hasSuscription.data ? 'already_suscribed' : 'not_suscribed',
			{
				path,
				uid: uid ?? 'NO',
			},
		)
		router.push(path)
	}

	if (hasSuscription.isLoading) {
		if (commonCorporate(client)) {
			return <CardSkeleton />
		}
		return (
			<div className="grid place-content-center h-full">
				<Loader size={'size-5'} color="stroke-primary" />
			</div>
		)
	}

	if (hasSuscription.data) {
		return (
			<ButtonService
				id={'suscripciones'}
				isNew={true}
				name={'BiHeart'}
				text={'Suscripciones'}
				action={() => saveLogAndRedirect('/my-subscriptions')}
				newText={'nuevo'}
			/>
		)
	}

	return (
		<ButtonService
			key={shortcut.field + shortcut.order}
			id={shortcut.field}
			isNew={shortcut.new ? true : undefined}
			name={shortcut.icon as IconsNames}
			text={shortcut.text}
			action={() => saveLogAndRedirect(shortcut.link)}
		/>
	)
}
