import { IconsNames, Text } from '@umahealth/occipital'
import { Icon } from '@umahealth/occipital/client'

interface IButtonService {
	name: IconsNames
	text: string
	id: string
	action: () => void
	isNew?: boolean
	highlight?: boolean
	notifications?: number
	newText?: string
}

function NotificationBubble({ notification }: { notification: number }) {
	if (notification === 0) {
		return null
	}
	return (
		<Text
			tag="span"
			className={
				'bg-success-hover w-5 h-5 right-0 rounded-full absolute top-0 flex items-center justify-center text-white font-bold text-sm'
			}
		>
			{notification}
		</Text>
	)
}

const ButtonServiceNew = ({
	name,
	id,
	text,
	action,
	isNew,
	notifications = 0,
	newText = 'nuevo',
}: IButtonService) => {
	return (
		<li className="w-full relative h-[118px]" id={id}>
			<button
				className="bg-white h-full w-full border border-solid border-[#CFD8DC] shadow-md rounded-2xl py-4 flex flex-col items-center justify-center text-center hover:shadow-lg transition-shadow"
				aria-labelledby={text}
				onClick={action}
			>
				<NotificationBubble notification={notifications} />
				<div className="mb-2 py-2">
					<Icon name={name} size="size-6" color={'text-grey-800'} />
					{isNew && (
						<span className="bg-green-100 text-green-800 font-semibold text-sm px-3 py-1 rounded-full absolute top-0 -right-3 capitalize z-10">
							{newText}
						</span>
					)}
				</div>
				<div className="w-full px-4 max-[375px]:px-8 min-[720px]:w-[80%] text-center text-balance">
					<p className="text-gray-800 font-regular text-xxs" id={text}>
						{text}
					</p>
				</div>
			</button>
		</li>
	)
}

export default ButtonServiceNew
