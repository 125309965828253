import React from 'react'

const CardSkeleton = () => {
	return (
		<div className="w-full min-h-[100px] p-4 bg-white rounded-lg shadow-md">
			<div className="h-8 w-8 bg-gray-300 rounded-full mx-auto mb-2" />
			<div className="h-4 w-full bg-gray-300 rounded-md mx-auto" />
		</div>
	)
}

export default CardSkeleton
