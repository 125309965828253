'use client'
import { commonCorporate } from '@/components/Utils/isCommonCorporate'
import { trackEventRegretButton } from '@/events/homeEvents'
import { useClientContext } from '@/OldRouter/Providers/ProviderClient'
import { Button } from '@umahealth/occipital'
import RegretButtonNew from './RegretButtonNew'

function RegretButton() {
	const client = useClientContext()

	if (commonCorporate(client)) return <RegretButtonNew />

	return (
		<Button
			className="mt-12 w-[64%] mx-auto"
			size="full"
			href="/regret"
			variant="outlined"
			type="link"
			onClick={() => trackEventRegretButton()}
		>
			Boton de arrepentimiento
		</Button>
	)
}

export default RegretButton
