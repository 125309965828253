import { trackEventRegretButton } from '@/events/homeEvents'
import { Button } from '@umahealth/occipital'

const RegretButtonNew = () => {
	return (
	<div className="p-6 flex justify-end flex-col w-full text-center bg-[#F5F7F9] mt-16">
		<h2 className="text-lg font-semibold text-gray-900">
			¿Te arrepentiste o querés dar de baja un producto?
		</h2>
		<p className="block text-left text-xxs text-[#546E7A] mt-4 mx-auto w-[90%] min-[390px]:w-[85%]">
			Podés dar de baja productos en cualquier momento o arrepentirte de alguna contratación
			que hayas hecho en los últimos 10 días.
		</p>
		<Button
			className="border-none mt-12 px-6 w-full mx-auto font-regular"
			size="full"
			href="/regret"
			variant="outlined"
			type="link"
			onClick={() => trackEventRegretButton()}
		>
			Botón de arrepentimiento
		</Button>
	</div>
	)
}

export default RegretButtonNew;
