import React from 'react'
import Slide from '../Slides'
import Carousel from '@/components/GeneralComponents/Carousel'
import { ICarouselSlide } from '@umahealth/entities'
import '../Slider.scss'

interface IHomeSlider {
	slidesInfo: ICarouselSlide[];
	autoplay?: boolean;
}

function Banner({ slidesInfo, autoplay }: IHomeSlider) {
	const settings = {
			align: 'center',
			dots: true,
			infinite: true,
			speed: 500,
			arrows: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: autoplay,
			autoplaySpeed: 5000,
		}

	return (
		<>
			{slidesInfo.length > 0 && (
				<Carousel
					opts={settings}
					className="w-full mt-2"
				>
					{slidesInfo.filter(item=> item.active).map((slide) => {
						return (
							<div className='rounded-lg' key={slide.title}>
								<Slide
									fullImage={slide.fullImage}
									title={slide.title}
									text={slide.text}
									cta={slide.cta}
									action={slide.action}
									img={slide.img}
									tracking={slide.id}
									tagId={slide.tagId}
								/> 
							</div>
						)
					})}
				</Carousel>
			)}
		</>
	)
}

export default Banner
