import React from 'react'
import { Title } from '@umahealth/occipital'

const MySubscriptionDrawerContent = ({
	onSelectOption,
}: {
	onSelectOption: (option: string) => void
}) => {
	const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		onSelectOption(e.target.value)
	}

	return (
		<div className="p-4 pt-0 my-2 flex flex-col gap-6">
			<p className='text-xs'>Tenés pendiente la activación de una suscripción. ¿Qué deseas hacer?</p>
			<label className="flex items-center gap-3">
				<input
					type="radio"
					name="option"
					value="validate"
					className="w-5 h-5"
					onChange={handleOptionChange}
				/>
				<div className="ml-2">
					<Title
						hierarchy="h3"
						color="text-grey-1"
						size='text-s'
						className='text-left'
					>
						Realicé el pago y necesito validarlo.
					</Title>
				</div>
			</label>
			<label className="flex items-center gap-3">
				<input
					type="radio"
					name="option"
					value="finalize"
					className="w-5 h-5"
					onChange={handleOptionChange}
				/>
				<div className="ml-2">
					<Title
						hierarchy="h3"
						color="text-grey-1"
						size='text-s'
						className='text-left'
					>
						Quiero abonar ahora.
					</Title>
				</div>
			</label>
		</div>
	)
}

export default MySubscriptionDrawerContent
