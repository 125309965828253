'use client'
import React, { useEffect, createContext, useContext } from 'react'
import { disableChatbot } from '@/components/Utils/disableChatbot'
import { IPatient } from '@umahealth/entities'
import parseSubdomain from './utils/parseSubdomain'
import { clients, ClientsNames, IClientState, iomaNormalize } from '@/config/clients'

export type LogoNames = 'UMA_LOGO' | 'IOMA_LOGO' | 'FARMATODO_LOGO'


const brand:string = parseSubdomain()

function getClient(patient: IPatient | null): IClientState | null {

	const corporateNorm = iomaNormalize(patient?.corporate_norm??'UMA')

	//Ejecutamos primero esta condicional ya que si el usuario está entrando por el subdominio de su OS no necesitamos esperar a que cargue toda la data
	if (brand === 'IOMA' || brand === 'FARMATODO') {
		return clients[brand]

	}

	if (!patient) {
		return null
	}

	return clients[corporateNorm as ClientsNames]??clients['UMA']
}

export const clientContext = createContext<IClientState|null>(null)

function ProviderClient({children, patient} : { children : React.ReactNode, patient: IPatient|null}){
	const client = getClient(patient)

	useEffect(() => {
		if((client?.client === 'IOMA' || client?.client === 'OSPRERA') && process.env.NEXT_PUBLIC_COUNTRY === 'AR') {
			disableChatbot()
		}
	}, [client?.client])

	return (
		<clientContext.Provider value={ client }>
			{children}
		</clientContext.Provider>
	)

}

export const useClientContext = () => useContext(clientContext)

export default ProviderClient
export type { IClientState }
