import React from 'react'
import { IconsNames } from '@umahealth/occipital'
import { Icon } from '@umahealth/occipital/client'
import Link from 'next/link'

const EventElementNew = ({ section, children }) => {
	return (
		<div className="w-full relative h-[118px]" key={section.title}>
			<Link
				href={section.link}
				className="bg-white h-full w-full border border-solid border-[#CFD8DC] shadow-md rounded-2xl py-4 flex flex-col items-center justify-center text-center hover:shadow-lg transition-shadow"
				id={section.title}
			>
				<div className="flex flex-col items-center justify-center">
					<Icon
						name={section.icon as IconsNames}
						size="size-6"
						color={'text-grey-800'}
						className="mb-4"
					/>
					<div className="w-full px-2 max-[375px]:px-4 min-[720px]:w-[80%] min-[720px]:px-0 text-center text-balance">
						<p className="text-gray-800 font-regular text-xxs">{section.title}</p>
					</div>
				</div>
				{children}
			</Link>
		</div>
	)
}

export default EventElementNew
