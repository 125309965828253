import React from 'react'
import { useRouter } from 'next/navigation'
import db from '@/config/DBConnection'
import styles from '../HomeSlider/Banner.module.scss'
import useTrack from '@/services/hooks/Amplitude/useTrack'
import { useDatalayerMutation } from '@/hooks/useDatalayerAsync'
import Image from '@/reusableComponents/Image'
import { trackEventHomeSlider } from '@/events/homeEvents'

const Slide = ({
	fullImage = '',
	title = '',
	text = '',
	img = '',
	cta = '',
	tagId = '',
	action = '',
	tracking = ''
} : {
	fullImage?: string
	title?: string
	text?: string
	img?: string
	cta?: string
	tagId?: string
	action?: string
	tracking?: string
}) => {
	const arr_text = text.split('|n')
	const track = useTrack()
	const history = useRouter()
	const dataLayerMutation = useDatalayerMutation()

	const trackClick = () => {
		track.mutate(
			{ eventInput: `[Home] Clic en slide ${title}` }
		)
		dataLayerMutation.mutate({ ecommerce: null })
		dataLayerMutation.mutate({
			instance: process.env.NEXT_PUBLIC_DATALAYER_INSTANCE,
			event: 'select_promotion',
			ecommerce: {
				promotion_name: 'Ofertas en top Home',
				creative_slot: 'slider_home',
				items: [
					{
						item_id: `${tracking}`,
						item_name: `${title}`,
					},
				],
			},
			user_id: db.auth().currentUser?.uid,
		})
		trackEventHomeSlider(
			{
				title,
				tracking
			}
		)
		history.push(action)
	}

	return (
		
		<div className={styles['slide']} onClick={trackClick} id={tagId}>

			{
				fullImage ?
					<div className={styles['slide__container']} id={tagId}>
						<div className={styles['slide__fullImage']} id={tagId}>
							<Image priority src={fullImage} alt={title} id={tagId} objectFit='cover' className='w-full h-full object-cover' />
						</div>
					</div>
					:
					<>
						<div className={styles['slide__container']} id={tagId}>
							<div className={styles['slide__content']} id={tagId}>
								<h1 className='text-xxs text-[#351959] uppercase font-regular' id={tagId}>
									{title}
								</h1>
								<div className='max-w-[150px]' id={tagId}>
									{arr_text.map((line) => (
										<p key={line} className='font-bold text-[#351959]' id={tagId}>
											{line}
										</p>
									))}
								</div>
								<button onClick={trackClick} id={tagId} className='bg-[#7135BF] rounded-full text-xxs font-regular text-[#fff] px-4 py-2 w-fit'>
									{cta}
								</button>
							</div>
						</div>
						{
							img && <div className={styles['slide__image']} id={tagId}>
								<Image priority src={img} alt={title} id={tagId} objectFit='cover' 
									width={600} height={600}
								/>
							</div>
						}
					</>
			}

		</div>


	)
}

export default Slide
