import React from 'react'
import { IPatient, HomeParametersObject, IShortcut, chat } from '@umahealth/entities'
import ButtonService from '@/components/HomePage/Components/ServiceButton/ServiceButton'
import { IconsNames } from '@umahealth/occipital'
import SpecialistShortcut from './VariableShorctus/SpecialistShortcut'
import useTrack from '@/services/hooks/Amplitude/useTrack'
import OnlineDoctorShortcut from './VariableShorctus/OnlineDoctorShorcut'
import ChatAttShortcut from './VariableShorctus/ChatAttShortcut'
import { useRouter } from 'next/navigation'
import { IClientState } from '@/config/clients'
import { SuscripcionesShortcut } from './VariableShorctus/SuscripcionesShortcut'
import { trackEventsHomeModules } from '@/events/homeEvents'
import posthog from 'posthog-js'
import { commonCorporate } from '@/components/Utils/isCommonCorporate'
import classNames from 'classnames'

interface IShortcuts {
	shortcutParameter: HomeParametersObject['shorcuts_content']
	user: IPatient | null
	client: IClientState
	chatAtt: chat | null
}

function Shortcuts({ shortcutParameter: shortcutParameters, user, client, chatAtt }: IShortcuts) {
	const router = useRouter()
	const track = useTrack()

	function redirectToModule(moduleLink: string) {
		track.mutate({
			eventInput: `[Home] Clic en módulo ${moduleLink}`,
		})
		router.push(moduleLink)
	}

	const openExternalLink = (externalLink: string) => {
		if (window) {
			window.open(externalLink, '_blank')
		}
	}

	const navigationHandler = (shortcut: IShortcut) => {
		trackEventsHomeModules(shortcut)
		if (shortcut.externalLink) {
			openExternalLink(shortcut.externalLink)
		} else {
			redirectToModule(shortcut.link)
		}
	}

	const validateClient = (client: IClientState) => {
		if (commonCorporate(client)) {
			return classNames(
				'w-full grid grid-cols-2 justify-items-center px-4 mt-6 mb-14 gap-4 min-[390px]:grid-cols-3 min-[390px]:gap-2 min-[390px]:px-1 min-[412px]:gap-6',
			)
		}

		return classNames(
			'w-full grid grid-cols-2 justify-items-center items-baseline gap-y-3 mt-10 min-[420px]:grid-cols-3 grid-cols-3'
		)
	}

	return (
		<div className={validateClient(client)}>
			{shortcutParameters.shorcuts
				.sort((a, b) => a.order - b.order)
				.map((shortcut) => {
					if (shortcut.active) {
						/** Todo esto es para que se vea bien el shorcut de 'sala de espera' y 'guardia' */
						if (shortcut.field === 'onlinedoctor') {
							return (
								<OnlineDoctorShortcut
									client={client}
									key={shortcut.field + shortcut.order}
									shortcut={shortcut}
									user={user}
								/>
							)
						}
						if (shortcut.field === 'my_specialist') {
							return (
								<SpecialistShortcut
									client={client}
									key={shortcut.field + shortcut.order}
									shortcut={shortcut}
									user={user}
								/>
							)
						}
						if (shortcut.field === 'chatAtt') {
							return (
								<ChatAttShortcut
									chatAtt={chatAtt}
									client={client}
									key={shortcut.field + shortcut.order}
									shortcut={shortcut}
									user={user}
								/>
							)
						}

						if (shortcut.field === 'subscriptions-shortcut') {
							return (
								<SuscripcionesShortcut
									shortcut={shortcut}
									key={shortcut.field + shortcut.order}
								/>
							)
						}

						if (
							(shortcut.field === 'apto-fisico' &&
								posthog?.__loaded &&
								!posthog.isFeatureEnabled('apto-fisico')) ||
							client.freeSpecialists ||
							!commonCorporate(client)
						) {
							return null
						}

						return (
							<ButtonService
								key={shortcut.field + shortcut.order}
								id={shortcut.field}
								isNew={shortcut.new ? true : undefined}
								name={shortcut.icon as IconsNames}
								text={shortcut.text}
								action={() => navigationHandler(shortcut)}
							/>
						)
					}
				})}
		</div>
	)
}

export default Shortcuts
