export const onlineSpecialistRoutes = {
	home: '/especialista/online',
	appointments: '/especialista/online/turnos',
	appointmentDetails: (appointmentId: string) => `/especialista/online/turnos/${appointmentId}` as const,
	cancelAppointmentApi: (appointmentId: string) => `/especialista/online/turnos/${appointmentId}/cancelar` as const,
	cancelledAppointment: (appointmentId: string) => `/especialista/online/turnos/${appointmentId}/turno-cancelado` as const,
	//confirmAppointment: (uid: string, appointmentId: string) => `/especialista/online/${uid}/confirmar-turno/${appointmentId}`,
	newAppointment: '/especialista/online/nuevo-turno',
	referredToAppointment: (appointmentId: string) => `/especialista/online/turnos/${appointmentId}/referred` as const,
	selectAppointment: (uid: string, providerUid: string) => `/especialista/online/${uid}/agenda/${providerUid}` as const,
	selectCoverage: (uid: string) => `/especialista/online/${uid}/cobertura` as const,
	selectReason: (uid: string) => `/especialista/online/${uid}/motivo` as const,
	selectSpecialty: (uid: string, coverage?: string) => `/especialista/online/${uid}/especialidad?cobertura=${coverage}` as const,
	selectSpecialist: (uid: string, specialty: string) => `/especialista/online/${uid}/especialidad/${specialty}` as const,
	confirmAppointment: (uid: string, appointmentId: string)  => `/marketplace?patientUid=${uid}&appointmentId=${appointmentId}` as const, // TO DO: Desactivar este confirm y activar el otro para terminar de migrar el módulo
}

export const restrictedSpecialties = ['aptofisico']