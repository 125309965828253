import { useFirestoreDocumentData } from '@react-query-firebase/firestore'
import { IDerivation } from '@/components/Derivations/ScreenSteps'
import { DocumentReference, Firestore, doc } from 'firebase/firestore'

function useActiveDerivations(firestore: Firestore, uid : string){
	const derivationReference = doc(firestore,`user/${uid}/active/derivation`) as DocumentReference<IDerivation>
	return useFirestoreDocumentData(['activeDerivations'], derivationReference, undefined, {
		enabled: !!uid
	})
}

export default useActiveDerivations