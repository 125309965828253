import { HomeParametersObject, ICarouselSlide, IPatient, chat } from '@umahealth/entities'
import Banner from '@/components/GeneralComponents/Banner/HomeSlider/Banner'
import React from 'react'
import EventsHistory from '../EventsHistory/EventsHistory'
import Shortcuts from './Shortcuts/Shortcuts'
import useActiveDerivations from '@/services/hooks/Firebase/useActiveDerivations'
import useContextUid from '@/services/firebase/useContextUid'
import { firestore } from '@/config/DBConnection'
import { IClientState } from '@/config/clients'
import { Button } from '@umahealth/occipital'
import RegretButton from '../Components/RegretButton'
import { isCommonCorprate } from '@/utils/validations/ValidateCoverage'
import BannerCorporates from '@/components/GeneralComponents/Banner/HomeSlider/corporates/BannerCorporates'

interface IModules {
	user: IPatient | null
	homeParameters: HomeParametersObject
	client: IClientState
	chatAtt: chat | null
}

function Modules({ user, client, homeParameters, chatAtt }: IModules) {
	const uid = useContextUid()
	const derivations = useActiveDerivations(firestore, uid ?? 'NO')

	const sortHeaderCarousel = (headerCarousel: ICarouselSlide[]) => {
		if (!headerCarousel?.length) return []

		return headerCarousel.sort((a, b) => a.order - b.order)
	}

	/** El orden de renderizado es importante */
	return (
		<div>
			<div>
				{derivations.data?.active &&
					derivations.data?.status !== 'Finalizado/No registrado' &&
					homeParameters?.derivation?.active && (
						<div className="mb-3 px-4" key="derivations">
							<Button
								className="flex"
								variant="filled"
								type="link"
								href={`/derivations/${uid}`}
							>
								Derivación hospitalaria en curso
							</Button>
						</div>
					)}
				{homeParameters?.header_carousel?.active && isCommonCorprate(client) &&(
					<div key="carousel" className='m-4'>
						<BannerCorporates
							slidesInfo={sortHeaderCarousel(homeParameters?.header_carousel?.slides)}
							autoplay={homeParameters?.header_carousel?.autoplay}
						/>
					</div>
				)}
				{
					homeParameters?.shorcuts_content?.active && !isCommonCorprate(client) && 
					(
						<div key="carousel" className='w-full'>
							<Banner
								slidesInfo={sortHeaderCarousel(homeParameters?.header_carousel?.slides)}
								autoplay={homeParameters?.header_carousel?.autoplay}
							/>
						</div>
					)
				}
				{homeParameters?.shorcuts_content?.active &&  (
					<div className="mb-10 px-4" key="shortcuts">
						<Shortcuts
							chatAtt={chatAtt}
							client={client}
							shortcutParameter={homeParameters.shorcuts_content}
							user={user}
						/>
					</div>
				)}
				{homeParameters?.history?.active && (
					<div key="history" className="px-4">
						<EventsHistory HistorySection={homeParameters?.history?.sections} />
					</div>
				)}
			</div>

			<RegretButton />
		</div>
	)
}

export default Modules
