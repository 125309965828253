import React from 'react'
import Slider from 'react-slick'
import styles from './BannerCorporates.module.scss'
import '../../Slider.scss'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import { ICarouselSlide } from '@umahealth/entities'
import SlideCorporate from '../../Slides/SlideCorporate'

interface IHomeSlider {
	slidesInfo: ICarouselSlide[];
	autoplay?: boolean;
}

function BannerCorporates({ slidesInfo, autoplay }: IHomeSlider) {
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		arrows: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: autoplay,
		autoplaySpeed: 5000,
	}

	return (
		<>
			{slidesInfo.length > 0 && (
				<Slider {...settings} className={styles['slider']}>
					{slidesInfo.map((slide) => {
						if (slide.active) {
							return (
								<div key={slide.title}>
									<SlideCorporate
										bg={slide.background}
										fullImage={slide.fullImage}
										title={slide.title}
										text={slide.text}
										cta={slide.cta}
										action={slide.action}
										img={slide.img}
										tracking={slide.id}
										tagId={slide.tagId}
									/>
								</div>
							)
						}
					})}
				</Slider>
			)}
		</>
	)
  
}
export default BannerCorporates