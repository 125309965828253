import React from 'react'
import { IconsNames, Text, textColors } from '@umahealth/occipital'
import { Icon } from '@umahealth/occipital/client'
import styles from './styles.module.scss'
import classNames from 'classnames'
import { useClientContext } from '@/OldRouter/Providers/ProviderHooks'
import ButtonServiceNew from './ServiceButtonNew'
import { commonCorporate } from '@/components/Utils/isCommonCorporate'
interface IButtonService {
	name: IconsNames
	text: string
	id: string
	action: () => void
	isNew?: boolean
	highlight?: boolean
	notifications?: number
	newText?: string
}

function NotificationBubble({ notification }: { notification: number }) {
	if (notification === 0) {
		return null
	}
	return (
		<Text
			tag="span"
			className={
				'bg-success-hover w-5 h-5 right-0 rounded-full absolute top-0 flex items-center justify-center text-white font-bold text-sm'
			}
		>
			{notification}
		</Text>
	)
}
function ButtonService({
	name,
	id,
	text,
	action,
	isNew,
	highlight,
	notifications = 0,
	newText = 'nuevo',
}: IButtonService) {
	const client = useClientContext()

	if (commonCorporate(client)) {
		return (
			<ButtonServiceNew
				name={name}
				id={id}
				text={text}
				action={action}
				isNew={isNew}
				highlight={highlight}
				notifications={notifications}
				newText={newText}
			/>
		)
	}

	const color: textColors = highlight ? 'text-secondary' : 'text-primary'

	return (
		<li id={id}>
			<button
				className={
					'rounded-full border-0 bg-transparent flex justify-center items-center flex-col relative'
				}
				aria-labelledby={text}
				onClick={action}
			>
				<NotificationBubble notification={notifications} />
				<div
					className={classNames(
						highlight
							? styles.ButtonServiceIconContainerActive
							: styles.ButtonServiceIconContainer,
						'mb-2',
					)}
				>
					<Icon name={name} size="size-6" color={color}></Icon>
					{isNew ? (
						<Text
							size="text-xxs"
							weight="font-regular"
							color="text-grey-5"
							tag="span"
							className={styles.new}
						>
							{newText}
						</Text>
					) : null}
				</div>
				<div className={'w-16'}>
					<Text weight="font-regular" size="text-xs" color={color} tag={'span'} id={text}>
						{text}
					</Text>
				</div>
			</button>
		</li>
	)
}

export default ButtonService
